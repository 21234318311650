import type { LinkItem } from "~~/models/links";
import type { ProductDto } from "~~/models/products";
import type { CatalogSearchQueryParams, CatalogSearchResponse, CatalogSearchResponseProduct } from "~~/models/search";
import type { ProductSearchPropertyValueDto, ProductSearchQuery } from "~~/server/api/ecom/search/index.post";
import type { PublicStoreConfig } from "~~/models/store";
import { z } from "zod";

const _defaultPage = 1;

export async function useCatalog(
  store: PublicStoreConfig | null | undefined,
  cmsFilter: any,
  options: { immediate: boolean } = { immediate: false },
) {
  if (!store) {
    console.warn("Could not find store (setting 'allProductsCategoryId' is mandatory).");
  }

  const { trackProductList } = useGtmTracking();
  const { locale } = useI18n();
  const { push } = useRouter();
  const { query } = useRoute();

  // below states need to be global states so every instance of this composable works with the same values
  const catalogProductsPaged = useCatalogProductsPaged();
  const catalogProductsDisplay = useCatalogProductsDisplay();
  const catalogPropertyFilters = useCatalogPropertyFilters();
  const catalogPropertyFiltersHidden = useCatalogPropertyFiltersHidden();
  const catalogPagination = useCatalogPagination();
  const catalogSearchQuery = useCatalogSearchQuery();

  // https://vueschool.io/articles/vuejs-tutorials/zod-and-query-string-variables-in-nuxt
  const querySchema = z.object({
    page: z.coerce.number().optional().default(1),
    propertyFilters: z.string().optional(),
    term: z.string().optional(),
  });

  const validQueryData = computed(() => {
    try {
      return querySchema.parse(query);
    }
    catch (e) {
      console.warn("Invalid query string", e);
      return null;
    }
  });

  if (options.immediate) {
    catalogSearchQuery.value.term = validQueryData.value?.term ?? undefined;
    catalogSearchQuery.value.page = validQueryData.value?.page ?? _defaultPage;

    if (cmsFilter) {
      catalogSearchQuery.value.propertyFilters = parsePropertyFiltersFromCms(cmsFilter);
      catalogPropertyFiltersHidden.value.push(...(parsePropertyFiltersFromCms(cmsFilter)?.map(cf => cf.propertyId) ?? []));
    }
    else if (validQueryData.value?.propertyFilters) {
      catalogSearchQuery.value.propertyFilters = parsePropertyFilters(validQueryData.value?.propertyFilters);
      catalogPropertyFiltersHidden.value = [];
    }
    else {
      catalogSearchQuery.value.propertyFilters = undefined;
      catalogPropertyFiltersHidden.value = [];
    }
  }

  const setCatalogProducts = async () => {
    const currentPage = catalogSearchQuery.value.page ?? validQueryData.value?.page ?? _defaultPage;

    if (!catalogProductsPaged.value) {
      return;
    }

    const products = catalogProductsPaged.value[currentPage] as Array<CatalogSearchResponseProduct>;

    if (!products) {
      return;
    }

    const productIdList = products
      .sort((a: CatalogSearchResponseProduct, b: CatalogSearchResponseProduct) => a.sortOrder - b.sortOrder)
      .map((p: CatalogSearchResponseProduct) => p.productId);

    catalogProductsDisplay.value = await $fetch<Array<ProductDto>>("/api/ecom/products/list", {
      query: {
        productIdList: productIdList,
        addExternalData: true,
        addProductTranslations: true,
        addFiles: true,
        addPropertyValues: true,
      },
    });

    if (catalogProductsDisplay.value && catalogProductsDisplay.value.length > 0) {
      const datalayerDtoList = catalogProductsDisplay.value
        .filter(p => p !== null && p !== undefined)
        .map(p => p.dataLayerDto!);

      trackProductList(datalayerDtoList, "Results");
    }
  };

  const { status, execute, error } = useFetch("/api/ecom/search", {
    // server: false,
    method: "POST",
    lazy: true,
    immediate: options.immediate,
    dedupe: "defer",
    body: computed(() =>
      ({
        term: catalogSearchQuery.value.term,
        categoryId: store?.allProductsCategoryId,
        propertyValueFilterList: catalogSearchQuery?.value.propertyFilters,
        pageSize: 9, // by design
      } as ProductSearchQuery),
    ),
    watch: false,
    deep: false,
    onRequest: ({ request, options }) => {
      // eslint-disable-next-line no-console
      console.log(`fetch (request: ${JSON.stringify(request)} | body: ${JSON.stringify(options.body)})`);
    },
    onResponse: async ({ response }) => {
      const responseData = response._data as CatalogSearchResponse;
      // console.log("fetch products response", responseData);

      catalogPagination.value.pageSize = responseData.pageSize;
      catalogPagination.value.totalItems = responseData.resultCount;

      catalogProductsPaged.value = responseData.pagedProducts;
      await setCatalogProducts();

      const selectedPropertyFilters = catalogSearchQuery?.value.propertyFilters ?? [] as Array<ProductSearchPropertyValueDto>;
      catalogPropertyFilters.value = await mapToPropertyFilters(
        responseData,
        selectedPropertyFilters,
        catalogPropertyFiltersHidden.value,
        locale.value,
      );
    },
    onResponseError: ({ response }) => {
      console.error("Could not get catalog data", response);
    },
  });

  // when a search param gets updated in the state, reflect the change in the url query params
  watch(catalogSearchQuery, (newSearchQuery) => {
    let newQueryParams: CatalogSearchQueryParams = {
      page: newSearchQuery.page ?? 1,
    };

    if (newSearchQuery.term && newSearchQuery.term.length > 0) {
      newQueryParams = {
        ...newQueryParams,
        term: newSearchQuery.term.toString(),
      };
    }

    if (newSearchQuery.propertyFilters && newSearchQuery.propertyFilters.length > 0) {
      newQueryParams = {
        ...newQueryParams,
        propertyFilters: JSON.stringify(newSearchQuery.propertyFilters),
      };
    }

    push({
      query: {
        page: newQueryParams.page,
        propertyFilters: newQueryParams.propertyFilters ?? undefined,
        term: newQueryParams.term ?? undefined,
      },
    });
  }, {
    deep: true,
  });

  watch(() => catalogSearchQuery.value.page, () => document?.querySelector("#product-search-base")?.scrollIntoView({ behavior: "smooth" }));

  const page = computed({
    get: () => {
      return catalogSearchQuery.value.page;
    },
    set: async (newPage: number) => {
      catalogSearchQuery.value.page = newPage;

      await setCatalogProducts();
    },
  });

  const propertyFilters = computed<Array<ProductSearchPropertyValueDto> | null | undefined > ({
    get: () => {
      return catalogSearchQuery.value.propertyFilters;
    },
    set: async (newPropertyFilters?: Array<ProductSearchPropertyValueDto> | null | undefined) => {
      if (catalogSearchQuery.value.propertyFilters === newPropertyFilters) {
        return;
      }

      catalogSearchQuery.value.propertyFilters = newPropertyFilters;
      catalogSearchQuery.value.page = 1;

      await execute();
    },
  });

  const term = computed<string | null | undefined>({
    get: () => {
      return catalogSearchQuery.value.term;
    },
    set: async (newTerm?: string | null | undefined) => {
      if (catalogSearchQuery.value.term === newTerm) {
        return;
      }

      catalogSearchQuery.value.term = newTerm;
      catalogSearchQuery.value.propertyFilters = undefined;
      catalogSearchQuery.value.page = 1;

      await execute();
    },
  });

  const updatePropertyFilters = () => {
    const newPropertyFilters = catalogPropertyFilters.value
      .flatMap(pg => pg.selectedList?.filter(s => s !== null && s !== undefined)
        .map((s: number) => {
          return { propertyId: pg.id, propertyValueId: s } satisfies ProductSearchPropertyValueDto;
        }));

    propertyFilters.value = newPropertyFilters?.filter(s => s !== null && s !== undefined);
  };

  const navigate = async (link: LinkItem) => {
    if (link.target === "_blank") {
      await navigateTo(link.href, { external: true });
    }
    else {
      await navigateTo(link.href);
    }
  };

  return {
    pending: status.value === "pending",
    error: error,
    products: catalogProductsDisplay,
    propertyFilters: catalogPropertyFilters,
    pagination: catalogPagination,
    page: page,
    term: term,
    activePropertyFilters: propertyFilters,
    updatePropertyFilters: updatePropertyFilters,
    navigate: navigate,
  };
}
